import { twMerge } from 'tailwind-merge';
import { forwardRef } from 'react';
import Flex from '../flex/Flex';

type CardProps = {
  children: React.ReactNode | string;
  className?: string;
  shadow?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const Card = forwardRef<HTMLDivElement, CardProps>(
  ({ children, className, shadow, ...props }, ref) => {
    return (
      <Flex
        className={twMerge(
          'flex-col py-6 px-4 gap-6 bg-white border border-solid border-gray-100 rounded-2xl w-full',
          shadow &&
            'hover:shadow-md transition-shadow duration-300 ease-in-out',
          className
        )}
        data-testid="card"
        ref={ref}
        {...props}
      >
        {children}
      </Flex>
    );
  }
);

Card.displayName = 'Card';
export default Card;
