'use client';

import { CacheProvider } from '@chakra-ui/next-js';
import { Provider as JotaiProvider } from 'jotai';
import { useHydrateAtoms } from 'jotai/utils';
import { SessionProvider } from 'next-auth/react';
import '~/styles/index.css';

import { acceptedCookiePolicyAtom } from '~/atoms';
import { I18nProviderClient } from '~/locales/client';
import theme from '~/styles/theme';

import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import { AcceptedCookiePolicyType } from './_components/CookieBanner/types';
import { Tracking } from './_components/Tracking';

export const Providers: React.FC<{
  children: React.ReactNode;
  locale: string;
  cookiePolicy: AcceptedCookiePolicyType;
}> = ({ children, locale, cookiePolicy }) => {
  return (
    <>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <SessionProvider>
        <I18nProviderClient locale={locale}>
          <JotaiProvider>
            <CacheProvider>
              <ChakraProvider theme={theme}>
                <CookiePolicyInitiator cookiePolicy={cookiePolicy} />
                {children}
                <Tracking locale={locale} />
              </ChakraProvider>
            </CacheProvider>
          </JotaiProvider>
        </I18nProviderClient>
      </SessionProvider>
    </>
  );
};

const CookiePolicyInitiator = ({
  cookiePolicy,
}: {
  cookiePolicy: AcceptedCookiePolicyType;
}) => {
  useHydrateAtoms([[acceptedCookiePolicyAtom, cookiePolicy]]);
  return null;
};
