import { isDesktop, isMobileOnly, isTablet } from 'react-device-detect';
import {
  SEARCH_PARAM_CONFIRMATION_MODAL,
  SEARCH_PARAM_STATUS,
} from '~/app/[locale]/home/offers/request/constants';
import {
  ApiQuoteStatusEnum,
  TermsAndConditionsEnum,
} from '~/app/[locale]/home/offers/types';

export const DEVICE_TYPE = {
  MOBILE: 'Mobile',
  TABLET: 'Tablet',
  DESKTOP: 'Desktop',
  UNKNOWN: 'Unknown',
};

type DeviceTypeProps = {
  isMobile?: boolean;
  isNotMobile?: boolean;
  isDesktop?: boolean;
  isTablet?: boolean;
};

/**
 * Get the device type based on react-device-detect library
 * @returns DeviceTypeProps
 */
export const getDeviceTypeClientSide = (): DeviceTypeProps => {
  if (typeof navigator === 'undefined') {
    return {
      isMobile: false,
      isNotMobile: false,
      isDesktop: true, // Default to desktop if userAgent is not available
      isTablet: false,
    };
  }

  return {
    isMobile: isMobileOnly,
    isNotMobile: !isMobileOnly,
    isDesktop: isDesktop,
    isTablet: isTablet,
  };
};

/**
 * Updates the URL search params with the modal=true status=ApiQuoteStatusEnum
 * So that the ConfirmationModal listens to the event and opens the confirmation modal
 * Update the browser's history stack without rerendering the page.
 * @param status
 */
export const openConfirmationModal = (
  status?: ApiQuoteStatusEnum | TermsAndConditionsEnum
) => {
  updateSearchParams(
    `${SEARCH_PARAM_CONFIRMATION_MODAL}=true&${SEARCH_PARAM_STATUS}=${status}`
  );
};

/**
 * Client side update only
 * Updates the URL search params with the params provided
 * Update the browser's history stack without rerendering the page.
 * @param params
 */
export const updateSearchParams = (params: URLSearchParams | string) => {
  window.history.replaceState(null, '', `?${params.toString()}`);
};

export const redirectToPage = (
  pathname: string,
  params?: URLSearchParams | string
) => {
  const paramsString = params ? `?${params?.toString()}` : '';
  window.history.replaceState(null, '', `${pathname}${paramsString}`);
};

/**
 * Gets item from local storage based on provided key
 * @param localStorageKey
 * @returns
 */
export const getItemFromLocalStorage = (localStorageKey: string) => {
  if (typeof window !== 'undefined') {
    return localStorage.getItem(localStorageKey);
  }
};

/**
 * Sets item to local storage based on provided key and value
 * @param localStorageKey
 * @param value
 * @returns
 */
export const setItemToLocalStorage = (
  localStorageKey: string,
  value: string
) => {
  if (typeof window !== 'undefined') {
    return localStorage.setItem(localStorageKey, value);
  }
};

/**
 * Function to update local storage and trigger custom event
 */
export const updateHasSeenModal = (value: string) => {
  setItemToLocalStorage('hasSeenModal', value);
  const event = new Event('customLocalStorageEvent');
  window.dispatchEvent(event);
};

export const isBrowserClientSide = () => typeof window !== 'undefined';

/**
 * Checks if the current device is a touch device.
 *
 * @return {boolean} True if the device is a touch device, false otherwise.
 */
export const isTouchDevice = () => {
  if (isBrowserClientSide()) {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  }
  return false;
};
