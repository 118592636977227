import Link from 'next/link';
import { twMerge } from 'tailwind-merge';
import colors from 'tailwindcss/colors';
import { Icon } from '~/components/core/Icon';
import Flex from '~/components/customComponents/flex/Flex';
import Text from '~/components/customComponents/texts/Text';
import { BULLET_POINT } from '~/constants';
import { AndercoreExternalLinks } from '~/constants/endpoints';
import { NUMBER_REGEX, SPECIAL_CHARACTER_REGEX } from '~/constants/regex';
import { useCurrentLocale, useI18n } from '~/locales/client';
import { spacing } from '~/utils/tailwindUtils';

interface CreationAlertProps {
  showDisclaimer?: boolean;
  password: string;
}

export const CreationAlert = ({
  showDisclaimer,
  password,
}: CreationAlertProps) => {
  const t = useI18n();
  const locale = useCurrentLocale();
  const copy = {
    rule_min_length: t('page.create-password.view.creation.rule.1'),
    rule_include_number: t('page.create-password.view.creation.rule.2'),
    rule_special_char: t('page.create-password.view.creation.rule.3'),
    disclaimer: t('page.create-password.view.disclaimer.with.links', {
      termsAndConditions: (
        <Link
          href={AndercoreExternalLinks[locale].terms}
          className="underline text-gray-900 hover:text-secondary"
        >
          {t('page.create-password.view.disclaimer.terms.and.conditions')}
        </Link>
      ),
      privacyPolicy: (
        <Link
          href={AndercoreExternalLinks[locale].privacy}
          className="underline text-gray-900 hover:text-secondary"
        >
          {t('page.create-password.view.disclaimer.privacy.policy')}
        </Link>
      ),
    }),
  };

  const lengthIsFulfilled = password.length >= 8;
  const hasNumberIsFulfilled = NUMBER_REGEX.test(password);
  const hasSpecialCharacterIsFulfilled = SPECIAL_CHARACTER_REGEX.test(password);

  const ListItem = ({
    children,
    isFulfilled,
  }: {
    children: string;
    isFulfilled: boolean;
  }) => {
    return (
      <li className={twMerge(isFulfilled && 'text-green-800')}>
        <Flex className="items-center">
          {isFulfilled ? (
            <Icon
              color={colors.green[800]}
              name={'check'}
              size={spacing[4]}
              className="pr-2"
            />
          ) : (
            <Text className="pl-1 pr-3">{BULLET_POINT}</Text>
          )}
          <Text>{children}</Text>
        </Flex>
      </li>
    );
  };

  return (
    <Flex className="gap-3 flex-col">
      <Flex className="flex-col">
        <ul className="text-xs font-medium text-gray-800 list-none mt-3">
          <ListItem isFulfilled={lengthIsFulfilled}>
            {copy.rule_min_length}
          </ListItem>
          <ListItem isFulfilled={hasNumberIsFulfilled}>
            {copy.rule_include_number}
          </ListItem>
          <ListItem isFulfilled={hasSpecialCharacterIsFulfilled}>
            {copy.rule_special_char}
          </ListItem>
        </ul>
      </Flex>
      {showDisclaimer ? (
        <Text className="py-4 lg:py-10  font-medium text-xs text-gray-800">
          {copy.disclaimer}
        </Text>
      ) : (
        <br />
      )}
    </Flex>
  );
};
