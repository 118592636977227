import {
  Box,
  Card,
  Button as ChakraButton,
  Flex,
  FlexProps,
  Heading,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';

import { Button } from '~/components/core/Button';
import { useCurrentLocale, useScopedI18n } from '~/locales/client';
import { AcceptedCookiePolicyEnum, AcceptedCookiePolicyType } from './types';
import { AndercoreExternalLinks } from '~/constants/endpoints';
import Link from 'next/link';
import { getDeviceTypeClientSide } from '~/utils/clientUtils';
import { spacing } from '~/utils/tailwindUtils';

type CookieCardProps = {
  onOpen: () => void;
  acceptedCookiePolicy: AcceptedCookiePolicyType;
  setAcceptedCookiePolicy: (policy: AcceptedCookiePolicyType) => void;
};

export default function CookieCard({
  onOpen,
  acceptedCookiePolicy,
  setAcceptedCookiePolicy,
}: CookieCardProps) {
  const { isMobile } = getDeviceTypeClientSide();
  const t = useScopedI18n('cookieBanner');
  const locale = useCurrentLocale();

  const direction =
    (useBreakpointValue({
      base: 'column',
      xl: 'row',
    }) as FlexProps['direction']) || 'column';
  const buttonDirection =
    (useBreakpointValue({
      base: 'column',
      sm: 'row',
      xl: 'column',
    }) as FlexProps['direction']) || 'row';

  const buttonJustify = buttonDirection === 'row' ? 'flex-end' : 'center';
  const buttonWidth = buttonDirection === 'column' ? 'full' : 'auto';

  if (acceptedCookiePolicy) return null;
  return (
    <Card
      position="fixed"
      bottom={isMobile ? spacing[5] : spacing[4]}
      left="50%"
      transform="translateX(-50%)"
      maxW={{
        base: '100%',
        sm: '480px',
        md: '600px',
        lg: '800px',
        xl: '960px',
        '2xl': '1100px',
      }}
      bg="white"
      boxShadow="xl"
      p={4}
    >
      <Flex direction={direction} justify="space-between" align={buttonJustify}>
        <Box flex="2" p={2}>
          <Heading as="h3" size="md" mb={4}>
            {t('toast.title')}
          </Heading>
          <Text>{t('toast.text')}</Text>
          <Box mt={2}>
            <Link
              className="underline hover:text-secondary"
              href={AndercoreExternalLinks?.[locale]?.privacy ?? ''}
            >
              {t('toast.privacy-policy')}
            </Link>
          </Box>
        </Box>
        <Flex flex="1" direction={buttonDirection} align="center" p={2}>
          <ChakraButton
            mb={buttonDirection === 'column' ? 4 : 0}
            mr={buttonDirection === 'column' ? 0 : 2}
            width={buttonWidth}
            onClick={onOpen}
            variant="outline"
            rounded={'2xl'}
          >
            {t('toast.button.customise')}
          </ChakraButton>
          <Button
            width={buttonWidth}
            mode="basic"
            onClick={() =>
              setAcceptedCookiePolicy(AcceptedCookiePolicyEnum.All)
            }
          >
            {t('toast.button.accept')}
          </Button>
        </Flex>
      </Flex>
    </Card>
  );
}
