'use client';

import { useRouter } from 'next/navigation';
import { SyntheticEvent, useCallback, useEffect, useState } from 'react';

import { Button } from '~/components/core/Button';
import { FormField } from '~/components/core/FormField';
import { useI18n } from '~/locales/client';

import { PASSWORD_REGEX } from '~/constants/regex';

import { Icon } from '~/components/core/Icon';
import Flex from '~/components/customComponents/flex/Flex';
import Text from '~/components/customComponents/texts/Text';
import { colors, spacing } from '~/utils/tailwindUtils';
import { updateHasSeenModal, updateSearchParams } from '~/utils/clientUtils';
import { postSetPassword } from '../../setup-password/requests';
import { CreationAlert } from './CreationAlert';

interface PasswordMatcherProps {
  email?: string;
  mode: 'create' | 'reset' | 'update';
  isFirstTimeUser?: boolean;
  isSetPasswordModal?: boolean;
}

const PasswordMatcher: React.FC<PasswordMatcherProps> = ({
  email,
  mode,
  isFirstTimeUser,
  isSetPasswordModal,
}) => {
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [passwordConfirmError, setPasswordConfirmError] = useState(false);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [isResetButtonLoading, setIsResetButtonLoading] = useState(false);
  const [subMissionError, setSubMissionError] = useState(false);
  const t = useI18n();
  const router = useRouter();

  const copy = {
    title: isFirstTimeUser
      ? t('component.password-matcher.view.title')
      : t('page.reset-password.view.title'),
    description: t('component.password-matcher.view.description'),
    label: t(`component.password-matcher.view.email.label`),
    placeholder: t(`component.password-matcher.view.email.placeholder`),
    confirmLabel: t(`component.password-matcher.view.email.confirm.label`),
    confirmPlaceholder: t(
      `component.password-matcher.view.email.confirm.placeholder`
    ),
    errorLabel: t(`component.password-matcher.view.email.error`),
    errorConfirmLabel: t(`component.password-matcher.view.email.confirm.error`),
    submitButtonLabel: isFirstTimeUser
      ? t(`component.password-matcher.view.${mode}.submit.button`)
      : t(`page.reset-password.view.button`),
  };

  useEffect(() => {
    const isPasswordValid = PASSWORD_REGEX.test(password);
    const arePasswordsMatching = password === passwordConfirm;
    setIsButtonEnabled(isPasswordValid && arePasswordsMatching);
    setPasswordConfirmError(
      passwordConfirm.length >= 8 && !arePasswordsMatching
    );
  }, [password, passwordConfirm]);

  const onPasswordChange = useCallback(
    (value: string) => {
      setPasswordConfirmError(false);
      setPassword(value);
    },
    [setPassword]
  );
  const onPasswordConfirmChange = useCallback((value: string) => {
    setPasswordConfirmError(false);
    setPasswordConfirm(value);
  }, []);

  const onSubmitHandler = async (password: string) => {
    const res = await postSetPassword(password);

    if (res?.ok) {
      if (isSetPasswordModal && isFirstTimeUser) {
        updateHasSeenModal('true'); // sets the hasSeenModal to true in the local storage
        updateSearchParams(''); // removes the search param - set-password-modal from the URL
      } else {
        router.push(isFirstTimeUser ? '/?welcome=true' : '/');
      }
    } else {
      return false;
    }
  };

  const handleFormResetSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    setIsResetButtonLoading(true);

    const res = await onSubmitHandler(password);

    setTimeout(() => {
      if (!res) {
        setSubMissionError(true);
      }
      setIsResetButtonLoading(false);
    }, 2000);
  };

  return (
    <Flex className="min-w-full justify-center items-center flex-col">
      <Flex className="justify-center flex-col px-4 min-h-full max-w-100">
        <form onSubmit={handleFormResetSubmit}>
          <Flex className="mb-6 md:mb-8">
            <Flex className="flex-col">
              <h2 className="text-2xl md:text-[28px] font-bold">
                {copy.title}
              </h2>
              <Text className="text-base mt-4 font-medium text-gray-800">
                {copy.description}
              </Text>

              {email && (
                <>
                  <Flex className="px-3 py-2 border border-gray-100 rounded-full items-center gap-2 mt-2">
                    <Icon
                      size={spacing[6]}
                      name="mail"
                      color={colors.gray[900]}
                    />
                    <Text className="text-base font-medium text-gray-900">
                      {email}
                    </Text>
                  </Flex>
                  <Text className="text-xs text-gray-800 mt-2">
                    {t('component.password-matcher.view.email.not.you')}
                  </Text>
                </>
              )}
            </Flex>
          </Flex>
          <FormField
            label={copy.label}
            name="password"
            placeholder={copy.placeholder}
            type="password"
            onChange={onPasswordChange}
            value={password}
            isInvalid={passwordConfirmError}
            errorMessage={copy.errorConfirmLabel}
          />
          <FormField
            label={copy.confirmLabel}
            name="passwordConfirm"
            placeholder={copy.confirmPlaceholder}
            type="password"
            onChange={onPasswordConfirmChange}
            value={passwordConfirm}
            isInvalid={passwordConfirmError}
            errorMessage={copy.errorConfirmLabel}
            style={{
              marginBottom: 0,
            }}
          />
          <CreationAlert password={password} showDisclaimer={isFirstTimeUser} />
          <Button
            mode="basic"
            minWidth={'100%'}
            isDisabled={!isButtonEnabled}
            isLoading={isResetButtonLoading}
            type="submit"
          >
            {copy.submitButtonLabel}
          </Button>
        </form>
      </Flex>
      {subMissionError && (
        <Text className="text-xs text-red-500 mt-4">
          {t('component.password-matcher.view.submission.failure.title')}
        </Text>
      )}
    </Flex>
  );
};

export { PasswordMatcher };
