'use client';

import { twMerge } from 'tailwind-merge';

type TextProps = {
  children: string | React.ReactNode;
  className?: string;
} & React.HTMLAttributes<HTMLDivElement>;

const Text = ({ children, className, ...props }: TextProps) => {
  return (
    <div
      className={twMerge(
        'text-primary font-normal text-sm leading-4 align-middle',
        className
      )}
      data-testid="text"
      {...props}
    >
      {children}
    </div>
  );
};

export default Text;
