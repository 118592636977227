import { extendTheme } from '@chakra-ui/react';
import { Inter, Archivo } from 'next/font/google';

const config = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

const colors = {
  'brand-red': {
    500: '#D30B0B',
    600: '#D30B0B',
  },
  gray: {
    50: '#F8F9FA',
    100: '#EBEDEF',
    200: '#DDE1E4',
    300: '#CED3D8',
    400: '#BDC4CB',
    500: '#95A1AC',
    600: '#808A93',
    700: '#646D74',
    800: '#3B3F44',
    900: '#27292D',
  },
};

const inter = Inter({ subsets: ['latin'] });
const archivo = Archivo({ subsets: ['latin'], variable: '--font-archivo' });

const fonts = {
  body: inter.style.fontFamily,
  heading: archivo.style.fontFamily,
};

const theme = extendTheme({
  config,
  fonts,
  colors,
  focusBorderColor: 'black',
});

export default theme;
